import React, { Component } from "react";
import { Link } from "react-router-dom";

// Elements
import FeatureSection3 from "../elements/feature-section3";
import TeamSection from "../elements/team";
import LatestNewsSection from "../elements/latest-news-slider";
import telemedicine from "../../images/icon/telemedicine.png";
// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import testPic2 from "../../images/testimonials/pic2.jpg";
import depression from "../../images/testimonials/depression.png";
import depression_image from "../../images/testimonials/despression1.png";
import anxiety from "../../images/testimonials/anxiety.png";
import ptsd from "../../images/testimonials/ptsd.jpg";

class Anxiety extends Component {
  render() {
    return (
      <>
        <div className="page-content bg-white">
         

       
          <section className="section-area section-sp3 team-wraper">
            <div className="container">
              <div className="heading-bx text-center">
                <h2 className="title-ext text-secondary">Anxiety</h2>
                {/* <h2 className="title">Meet Best Doctors</h2> */}
              </div>
              <div className="row justify-content-center">
                <>
                  <div style={{ display: "flex" }}>
                    <div className="col-lg-12 col-sm-6 mb-30">
                      <div className="team-member" style={{ display: "flex" }}>
                        <div className="col-lg-4">
                          <div className="team-media">
                            <img src={anxiety} alt={Anxiety} />
                          </div>
                        </div>
                        <div>
                          <div className="team-info">
                            <div className="team-info-content">
                              {/* <h4 className="title">Anxiety</h4> */}
                            </div>
                            {/* <ul className="social-media mt-3">
												{teamMember.socialMedia.map((social, index) => (
													<li key={index}>
														<a rel="noreferrer" target="_blank" href={social.link}>
															<i className={`fab fa-${social.platform.toLowerCase()}`} />
														</a>
													</li>
												))}
											</ul> */}
                          </div>
                          <p
                            style={{
                              textAlign: "left",
                              marginLeft: "1rem",
                              color: "#1f2278",
                            }}
                          >
                            Anxiety can make it challenging to deal with
                            everyday situations and make it difficult to handle
                            everyday tasks. Depending on the severity, you could
                            just feel symptoms of anxiety occasionally, and in
                            severe cases, frequently or all the time. If you
                            have anxiety, you may feel restless or worried, feel
                            easily fatigued, irritable, or difficulty sleeping.
                            Just like how we handle depression, we take a
                            holistic approach to developing your treatment plan
                            – we look at everything such as your sleeping
                            habits, lifestyle, and what your body and mind are
                            experiencing.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>

            {/* <img className="pt-img4 animate-wave" src={ptImg4} alt=""/>
					<img className="pt-img5 animate-wave" src={ptImg5} alt=""/> */}
          </section>

          {/* <LatestNewsSection /> */}
        </div>
      </>
    );
  }
}

export default Anxiety;
