import React, { useEffect, useState } from 'react';
import './FadingText.css'; // Import your CSS file with the animation styles

const texts = ['Schizophrenia', 'Adhd', 'Ocd', 'Ptsd',"Panic Disorder"]; // Add your text elements here

function FadingTexts() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 2000); // Interval set to the animation duration

    return () => clearInterval(interval);
  }, []);

  return (
   <div className="fading-text-container">
      {texts.map((text, index) => (
        <div
          key={index}
          className={`fade-text ${currentIndex === index ? 'visible' : ''}`}
        >
          {text}
        </div>
      ))}
    </div>
  );
}

export default FadingTexts;
