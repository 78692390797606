import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Import Images
import bg1 from '../../images/background/line-bg1.png';
import ptImg1 from '../../images/shap/circle-orange.png';
import ptImg2 from '../../images/shap/plus-orange.png';
import ptImg3 from '../../images/shap/circle-dots.png';

class aboutSection extends Component{
	render(){
		return(
			<>
				
				<section className="section-area section-sp5 work-area" style={{backgroundImage: "url("+bg1+")", backgroundRepeat:" no-repeat", backgroundPosition: "center", backgroundSize: "100%"}}>
					<div className="container-m">
						<div className="heading-bx text-center">
							<h1 className="title-ext text-secondary">Working Process</h1>
							<h2 className="title">How we work?</h2>
						</div>
						<div className="row justify-content-center">
							<div className="col-lg-3 col-sm-6 mb-30">
								<div className="work-bx">
									<div className="work-num-bx">01</div>
									<div className="work-content">
										<h5 className="title text-secondary mb-10">Make Appointment</h5>
										<p>Visit the Booking Section and book a slot for the service you want.</p>
									</div>
									<Link to="/booking" className="btn btn-primary light">Book Now <i className="btn-icon-bx fas fa-chevron-right"></i></Link>
								</div>
							</div>
							<div className="col-lg-3 col-sm-6 mb-30">
								<div className="work-bx active">
									<div className="work-num-bx">02</div>
									<div className="work-content">
										<h5 className="title text-secondary mb-10">Submit Intake Form</h5>
										<p>Once the appointment is confirmed, within 24hrs you will receive link via email to fill and submit required information. Please fill the required details and submit it to the practice before 24 hrs of the appointment.</p>
									</div>
									{/* <Link to="/intakeform" className="btn btn-primary light">Intake Form <i className="btn-icon-bx fas fa-chevron-right"></i></Link> */}
								</div>
							</div>
							<div className="col-lg-3 col-sm-6 mb-30">
								<div className="work-bx">
									<div className="work-num-bx">03</div>
									<div className="work-content">
										<h5 className="title text-secondary mb-10">Meet the Doctor</h5>
										<p>After reviewing the documents, your appointment will be approved and a video call link will be sent to you on registered email, please join the call 5 mins before the scheduled time. </p>
									</div>
									{/* <Link to="/contact-us" className="btn btn-primary light">View More <i className="btn-icon-bx fas fa-chevron-right"></i></Link> */}
								</div>
							</div>
							<div className="col-lg-3 col-sm-6 mb-30">
								<div className="work-bx active">
									<div className="work-num-bx">04</div>
									<div className="work-content">
										<h5 className="title text-secondary mb-10">Treatment</h5>
										<p>Following your appointment, you will receive a treatment plan that includes the prescription and recommendations from the doctor. Kindly adhere to this treatment regimen to facilitate a swift recovery.</p>
									</div>
									{/* <Link to="/contact-us" className="btn btn-primary light">View More <i className="btn-icon-bx fas fa-chevron-right"></i></Link> */}
								</div>
							</div>
						</div>
						
					</div>
					<img className="pt-img1 animate1" src={ptImg1} alt=""/>
					<img className="pt-img2 animate2" src={ptImg2} alt=""/>
					<img className="pt-img3 animate3" src={ptImg3} alt=""/>
				</section>
				
			</>
		);
	}
}

export default aboutSection;