import React, { useEffect, useRef, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';

const MyIframeComponent = () => {
  const iframeRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // const loadScript = () => {
    //   const iframe = iframeRef.current;
    //   const script = document.createElement('script');
    //   script.src = 'https://square.site/appointments/buyer/widget/m2wvwmwjuomobv/LB87CBXKCN37B.js';

    //   iframe.contentWindow.document.head.appendChild(script);
    // };

    // loadScript();
  }, []);

  return (
    <>
  <LoadingOverlay
  active={loading}
  spinner
  text='Loading...'>
    					<embed onLoad={() => setLoading(false)} src='https://squareup.com/appointments/book/m2wvwmwjuomobv/LB87CBXKCN37B/start' width='100%' height='600px' frameBorder='0'></embed>
</LoadingOverlay>
   
    </>
   
  );
};

export default MyIframeComponent;
