import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import servicesPic1 from "../../images/services/pic1.jpg";
import pdf from "../../images/icon/pdf.png";
import doc from "../../images/icon/doc.png";
class 	AboutFacility extends Component{
	render(){
		
		return(
			<>
					
					<section className="section-sp1 service-wraper2">
						<div className="container">
							<div className="row">
								<div className="col-lg-12 mb-30">
									
									<div className="clearfix">
										<div className="head-text mb-30">
											<h2 className="title mb-30" style={{textAlign:'center'}}>Practical Psychiatric Solutions</h2>
											<p className="mb-0">Welcome to Practical Psychiatric Solutions, where compassionate care and expert guidance intersect to empower individuals on their journey to mental well-being. Founded by Dr. Annette Deutscher, our practice is driven by a commitment to provide the highest standard of psychiatric care to every patient we serve. </p>
										</div>
										<div className="row align-items-center">
											<div className="col-md-6 mb-30">
												<ul className="list-check-squer mb-0">
													<li>Highly Trained Doctors</li>
													<li>Best Techniques</li>
													<li>Personal Care</li>
													
												</ul>
											</div>
											<div className="col-md-6 mb-30">
											<ul className="list-check-squer mb-0">
												
													<li>Facility Visitation</li>
													<li>TeleMedicine</li>
													<li>Flexible Scheduling</li>

												</ul>
												{/* <div className="skillbar-box mb-30">
													<h6 className="title">Advanced Technology</h6>
													<div className="skillbar appear">
														<p className="skillbar-bar" style={{width: "96%"}}></p>
														<span className="skill-bar-percent">96%</span>
													</div>
												</div>
												<div className="skillbar-box mb-30">
													<h6 className="title">Certified Doctors</h6>
													<div className="skillbar appear">
														<p className="skillbar-bar" style={{width: "79%"}}></p>
														<span className="skill-bar-percent">79%</span>
													</div>
												</div>
												<div className="skillbar-box mb-0">
													<h6 className="title">6 years Experience</h6>
													<div className="skillbar appear">
														<p className="skillbar-bar" style={{width: "75%"}}></p>
														<span className="skill-bar-percent">75%</span>
													</div>
												</div> */}
											</div>
										</div>
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h4 className="title mb-10">Our Philosophy</h4>
											<p className="mb-0">At Practical Psychiatric Solutions, we firmly believe that attentive listening is the cornerstone of effective psychiatric care. Dr. Deutscher's approach centers on taking the time to truly understand each patient's unique needs, enabling her to formulate precise diagnoses and craft personalized treatment plans. Beyond this, we emphasize the establishment of enduring collaborative relationships with our patients, fostering trust and a sense of partnership in their mental health journey. </p>
										</div>
										{/* <Accordion defaultActiveKey="0" className="accordion ttr-accordion1">
											<Accordion.Item eventKey="0">
												<Accordion.Header>How Doctor Can Ease Your Pain?</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="1">
												<Accordion.Header>How do I withdraw from a subject?</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="2">
												<Accordion.Header>Understand Doctor Before You Regret?</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="3">
												<Accordion.Header>What types of systems do you support?</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="4">
												<Accordion.Header>We Teach You How To Feel Better?</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="5">
												<Accordion.Header>How Can I Contact You?</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
												</Accordion.Body>
											</Accordion.Item>
										</Accordion> */}
									</div>
								</div>
								{/* <div className="col-lg-4">
									<aside className="sticky-top pb-1">
										<div className="widget">
											<ul className="service-menu">
												<li className="active"><Link to="/service-detail"><span>Engine Diagnostics</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-detail"><span>Lube Oil and Filters</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-detail"><span>Belts and Hoses</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-detail"><span>Air Conditioning</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-detail"><span>Brake Repair</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-detail"><span>Tire and Wheel Services</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										
										
									</aside>
								</div> */}
							</div>
						</div>
					</section>
				{/* <section className="section-sp1 service-wraper2">
					<div className="container">
						<div className="row">
							<div className="col-xl-3 col-sm-6 mb-30">
								<div className="feature-container feature-bx3">
									<h2 className="counter text-secondary">120</h2>
									<h5 className="ttr-title">Years With You</h5>
									<p>Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.</p>
								</div>
							</div>
							<div className="col-xl-3 col-sm-6 mb-30">
								<div className="feature-container feature-bx3">
									<h2 className="counter text-secondary">400</h2>
									<h5 className="ttr-title">Awards</h5>
									<p>Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.</p>
								</div>
							</div>
							<div className="col-xl-3 col-sm-6 mb-30">
								<div className="feature-container feature-bx3">
									<h2 className="counter text-secondary">250</h2>
									<h5 className="ttr-title">Doctors</h5>
									<p>Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.</p>
								</div>
							</div>
							<div className="col-xl-3 col-sm-6 mb-30">
								<div className="feature-container feature-bx3">
									<h2 className="counter text-secondary">800</h2>
									<h5 className="ttr-title">Satisfied Client</h5>
									<p>Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.</p>
								</div>
							</div>
						</div>
					</div>
				</section> */}
				
			</>
		);
	}
}

export default AboutFacility;