import React, { Component } from 'react';

// Import Images
import teamMember1 from "../../images/main-banner/annette.png";
import teamMember2 from '../../images/team/member2.jpg';
import teamMember3 from '../../images/team/member3.jpg';
import ptImg1 from '../../images/shap/trangle-orange.png';
import ptImg2 from '../../images/shap/square-dots-orange.png';
import ptImg3 from '../../images/shap/line-circle-blue.png';
import ptImg4 from '../../images/shap/wave-blue.png';
import ptImg5 from '../../images/shap/circle-dots.png';

const teamMembers = [
	{
		name: 'Dr. Annette Deutscher',
		specialization: 'Psychiatrist',
		image: teamMember1,
		socialMedia: [
			{ platform: 'Twitter', link: 'https://twitter.com/' },
			{ platform: 'LinkedIn', link: 'https://www.linkedin.com/' },
			{ platform: 'Instagram', link: 'https://www.instagram.com/' },
		],
	},
	// {
	// 	name: 'Dr. Mahfuz Riad',
	// 	specialization: 'Chiropractor',
	// 	image: teamMember2,
	// 	socialMedia: [
	// 		{ platform: 'Twitter', link: 'https://twitter.com/' },
	// 		{ platform: 'LinkedIn', link: 'https://www.linkedin.com/' },
	// 		{ platform: 'Instagram', link: 'https://www.instagram.com/' },
	// 	],
	// },
	// {
	// 	name: 'Dr. David Benjamin',
	// 	specialization: 'Cardiologist',
	// 	image: teamMember3,
	// 	socialMedia: [
	// 		{ platform: 'Twitter', link: 'https://twitter.com/' },
	// 		{ platform: 'LinkedIn', link: 'https://www.linkedin.com/' },
	// 		{ platform: 'Instagram', link: 'https://www.instagram.com/' },
	// 	],
	// },
];

class TeamSection extends Component{
	render(){
		return(
			<>
				
				<section className="section-area section-sp3 team-wraper">
					<div className="container">
						<div className="heading-bx text-center">
							<h2 className="title-ext text-secondary">Our Team</h2>
							{/* <h2 className="title">Meet Best Doctors</h2> */}
						</div>
						<div className="row justify-content-center">
							{teamMembers.map((teamMember, index) => (
								<>
								
								<div style={{display:"flex"}}>

								<div key={index} className="col-lg-12 col-sm-6 mb-30" >
									<div className="team-member" style={{display:"flex"}} >
										
										<div className='col-lg-4'>
										<div className="team-media">
											<img src={teamMember.image} alt={teamMember.name} />
										</div>
										
											</div>
											<div >
											<div className="team-info" >
											<div className="team-info-content">
												<h4 className="title">{teamMember.name}</h4>
												<h6><span className="text-secondary">{teamMember.specialization}</span></h6>
											</div>
											{/* <ul className="social-media mt-3">
												{teamMember.socialMedia.map((social, index) => (
													<li key={index}>
														<a rel="noreferrer" target="_blank" href={social.link}>
															<i className={`fab fa-${social.platform.toLowerCase()}`} />
														</a>
													</li>
												))}
											</ul> */}
										</div>
									<p style={{textAlign:"left",marginLeft:"1rem",color:"#1f2278"}}>
									 

Dr. Annette Deutscher is the visionary force behind Practical Psychiatric Solutions, shaping its commitment to exceptional mental healthcare. Hailing from the vibrant Tampa Bay Area, Dr. Deutscher's academic journey began at the University of South Florida, where she earned her undergraduate degree. Her relentless pursuit of knowledge took her on a global path, leading to international medical training and the attainment of a Master's in Health Administration. 

Before embarking on her psychiatric career, Dr. Deutscher honed her skills in Healthcare Operations in major cities like Chicago and Atlanta. Seeking specialized training, she made her way to the bustling metropolis of New York City. Her medical journey commenced with an internship in Family Medicine at Bronx Care (formerly Bronx Lebanon) in the Bronx, NY, followed by a psychiatric residency at St. Barnabas Hospital in the same borough. 
									</p>
									<p><b>Languages:</b> English, Español </p>
									</div>
										
									</div>
									
								</div>
								
								</div>
								</>
							))}
						</div>
					</div>
					<img className="pt-img1 animate1" src={ptImg1} alt=""/>
					<img className="pt-img2 animate2" src={ptImg2} alt=""/>
					<img className="pt-img3 animate-rotate" src={ptImg3} alt=""/>
					{/* <img className="pt-img4 animate-wave" src={ptImg4} alt=""/>
					<img className="pt-img5 animate-wave" src={ptImg5} alt=""/> */}
				</section>
				
			</>
		);
	}
}

export default TeamSection;