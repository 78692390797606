import testPic2 from "../../images/testimonials/pic2.jpg"
import depression from "../../images/testimonials/depression.png"
import depression_image from "../../images/testimonials/despression1.png"
import anxiety from "../../images/testimonials/anxiety.png"
import ptsd from "../../images/testimonials/ptsd.jpg"

export const  TreatmentList =  [{name: "OCD", 
description:"Obsessive-Compulsive Disorder (OCD) is a mental health condition characterized by persistent and intrusive thoughts (obsessions) that lead to repetitive behaviors or mental acts (compulsions) aimed at reducing the anxiety or discomfort caused by the obsessions.", 
image:depression},
{name: "Panic Disorder", 
description:"Depression, in any form, can make it more difficult to function in daily life. When it comes to depression, our providers take a holistic approach to developing your treatment plan – we look at everything such as your sleeping habits, lifestyle, and what your body and mind are experiencing. Psychiatric care and medication management can be helpful when treating symptoms of depression.", image:depression_image},
{name: "Anxiety", 
description:"Anxiety can make it challenging to deal with everyday situations and make it difficult to handle everyday tasks. Depending on the severity, you could just feel symptoms of anxiety occasionally, and in severe cases, frequently or all the time. If you have anxiety, you may feel restless or worried, feel easily fatigued, irritable, or difficulty sleeping. Just like how we handle depression, we take a holistic approach to developing your treatment plan – we look at everything such as your sleeping habits, lifestyle, and what your body and mind are experiencing. ", image:anxiety},
{name: "Post-Traumatic Stress Disorder (PTSD)", 
description:"Our psychiatry team can evaluate you for PTSD diagnosis. If you have PTSD, you may have risk factors for other mental health conditions. People develop anxiety or depression as part of the overall trauma response. It’s important to get a full evaluation from a healthcare provider following a traumatic experience. The right medication can help manage symptoms of PTSD..", image:ptsd},
]