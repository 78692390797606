import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Elements
import LatestNewsSection from "../elements/latest-news-slider";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";

const data = [{header:"What is Telemedicine?",body:"The American Telemedicine Association defines Telemedicine as “the remote delivery of health care services and clinical information using telecommunications technology” and can involve the use of internet, wireless, telephone, and satellite. To learn more about Telemedicine, please visit the American Telemedicine Association FAQ page. "},
{header:"What is Telepsychiatry?",body:"Telepsychiatry is the practice of psychiatry-specific telemedicine. The field of psychiatry is inherently well-suited to using telemedicine techniques as there is generally no physical exam component involved during appointments. These days, most telepsychiatry is done via real-time TeleVideo-conferencing methods very similar to a Skype or FaceTime session. As long as you are at a location with reasonably fast internet access, you can see an online psychiatrist for your appointment."},
{header:"Why See An Online Psychiatrist? ",body:'<b style="font-weight:700">Convenience and comfort</b>. No more battling congested traffic just to arrive at your appointment late. No more sitting in uncomfortable waiting rooms reading outdated magazines and trying to make awkward small talk with other patients. With telepsychiatry, there’s no need to leave your home or office. Just sit down in front of your computer, smartphone, or tablet at your appointment time and start your appointment. Then go back to whatever you were doing once your appointment is over. All you need is a broadband internet-connected computer with a webcam and microphone, smartphone, or tablet. If you can use Skype or Google Hangouts, you can conduct telepsychiatry appointments.' +
'<br><br><b  style="font-weight:700">Efficient high-quality care.</b> Research has shown that patients receiving telepsychiatry care are just as comfortable with their psychiatrists as patients receiving in-person psychiatric care, with similarly positive treatment outcomes. And because telepsychiatry appointments can be conducted from the patient’s home or office, hundreds of hours can potentially be saved each year from no longer needing to travel to and from the psychiatrist’s office or sitting in waiting rooms. '},
{header:"Where Does Dr. Annette See Patients?",body:'We currently provide services to <b style="font-weight:700">Florida, New York, New Jersey, Pennsylvania, Illinois, Maryland and North Carolina</b>.  More states may be added in the future as our practice grows. '},
{header:"Do You Take Insurance, Medicare, or Medicaid?",body:'<b style="font-weight:700">We are NOT in-network with any insurance, Medicare, nor Medicaid.</b> The process of billing insurance/Medicare/Medicaid is arduous and time-intensive, and requires practices hire professional medical billers to handle all the paperwork. By not accepting these third-party payers, it allows us to keep our rates low.' +
' If your insurance company will reimburse for telemedicine visits, you can always download your appointment paperwork and submit it yourself to your insurance for reimbursement. You can also pay using your HSA or FSA account, which many employers sponsor.'}]


const otherData = [{header:"How Much Does An Appointment With Dr. Annette Cost?",body:"Dr. Annette charges $300 for an initial assessment appointment which is 50 min. All follow up appointments (25 min) are $150. The Practical Psychiatric Solutions platform requires you provide credit card payment information before you can schedule an appointment. "},
{header:"What if you need to change or cancel an appointment?",body:'We do have a strict No-Show policy and you will be charged half the appointment fee if you are a No-Show. If you are more than 10 minutes late for any appointment, you will be considered a No-Show and charged accordingly'+
'. Although we understand that it may be necessary to miss an appointment, it is crucial for you to <b style="font-weight:700">cancel your appointment online or via phone call at least 12 hours before your schedule.</b> Otherwise, you will be charged 100% of the service fee amid cancellation. '},
{header:"How Do I Schedule An Appointment?  ",body:'Click <a href="https://practicalpsychsolutions.com/booking">here</a> to start scheduling an appointment with Dr. Annette.'},
{header:"Are There Services or Treatments Not Provided?",body:'Yes. Due to the increased regulatory requirements, we do not prescribe any controlled medications which are often used to treat the following conditions: '+
'<br> - <b style="font-weight:700">ADD/ADHD</b> (often treated with stimulants like Adderall, Concerta, Ritalin, Vyvanse, etc)' + 
'<br> - <b style="font-weight:700">Anxiety/Panic</b> (often treated with benzodiazepines like Xanax, Ativan, Klonopin, Valium, etc)'+
'<br> - <b style="font-weight:700">Insomnia </b> (often treated with controlled sleep medications like Ambien, Lunesta, Sonata, etc)'},
{header:"Non - Controlled Medication",body:'If you are NOT needing the controlled medications, then we offer treatment of those conditions using non-controlled medications.' +
' Some conditions like <b style="font-weight:700">Alcohol or drug addiction/abuse</b> require a higher level of care than can be safely provided by us via telepsychiatry, such as'+
'<br /><br />'+
'For following situations please call 911 or appropritate emergency services.'+
'<br> - Actively having <b style="font-weight:700">thoughts or urges to self-harm </b>' +
 '<b style="font-weight:700">    or attempt suicide</b>' +
'<br> - Actively having <b style="font-weight:700">thoughts or urges to harm someone else</b>' }]

class Faq extends Component{
	
	render(){

		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>FAQs</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Faqs</li>
										</ul>
									</nav>
								</div>
							</div>
							{/* <img className="pt-img1 animate-wave" src={waveBlue} alt=""/> */}
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-sp3">
						<div className="container">
							<div className="row">
								<div className="col-lg-6">
									<Accordion defaultActiveKey="0" className="accordion ttr-accordion1">
										{data.map((obj,val)=>{
											return (
												<Accordion.Item eventKey={val}>
											<Accordion.Header>{obj.header}</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0" dangerouslySetInnerHTML={{ __html: obj.body }} />
											</Accordion.Body>
										</Accordion.Item>
											)
										})}
										
									
									</Accordion>
								</div>
								<div className="col-lg-6">
									<Accordion defaultActiveKey="2" className="accordion ttr-accordion1">
									{otherData.map((obj,val)=>{
											return (
												<Accordion.Item eventKey={val}>
											<Accordion.Header>{obj.header}</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0" dangerouslySetInnerHTML={{ __html: obj.body }} />
											</Accordion.Body>
										</Accordion.Item>
											)
										})}
										
									</Accordion>
								</div>
							</div>
						</div>
					</section>
					
					{/* <LatestNewsSection /> */}
					
				</div>
			
			</>
		);
	}
}

export default Faq;