import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Import Images
import bg1 from '../../images/main-banner/bg1.jpg';
import doctorImg from '../../images/main-banner/doctor.png';
import ptImg1 from '../../images/shap/trangle-orange.png';
import ptImg2 from '../../images/shap/square-blue.png';
import ptImg3 from '../../images/shap/chicle-blue-2.png';
// import ptImg4 from '../../images/shap/plus-orange.png';
import ptImg4 from '../../images/shap/plus-blue.png';
import ptImg5 from '../../images/shap/wave-orange.png';

import FadingTexts from './FadingText';

class aboutSection extends Component{
	render(){
		return(
			<>
				
				<div className="main-banner" style={{backgroundImage: "url("+bg1+")"}}>
					<div className="container inner-content">
						<div className="row align-items-center">
							<div className="col-lg-7 col-md-6 col-sm-7">
								<h1>Discover Your Path to Mental Wellbeing</h1>
								<h5>Connect and Receive Support Within 48 Hours via Telehealth
								</h5>
								{/* <h6 className="title-ext text-primary">Practical Psychiatric Solutions</h6> */}
								<h5>Get Life-changing care for Anxiety, Depression, <FadingTexts></FadingTexts> </h5> 
								
								{/* <span className="fadeIn"><h1>panic</h1><h1>insomnia</h1><h1>bipolar</h1><h1>PTSD</h1><h1>suicidal thoughts</h1><h1>social anxiety</h1><h1>burnout</h1><h1>more</h1></span> */}
								{/* <h5>It gets much better from here. Get 1:1 help that
works, and lasts — from the best in online and personal psychiatry.</h5> */}
								<Link to="/about-us" className="btn btn-secondary btn-lg shadow">Read More</Link>
							</div>
							<div className="col-lg-5 col-md-6 col-sm-5">
								<div className="banner-img">
									<img src={doctorImg} alt=""/>
								</div>
							</div>
						</div>
					</div>
					{/* <img className="pt-img1 animate1" src={ptImg1} alt=""/> */}
					<img className="pt-img2 animate2" src={ptImg4} alt=""/>
					{/* <img className="pt-img3 animate3" src={ptImg3} alt=""/> */}
					{/* <img className="pt-img4 animate4" src={ptImg2} alt=""/> */}
					{/* <img className="pt-img5 animate-wave" src={ptImg5} alt=""/> */}
				</div>
				
			</>
		);
	}
}

export default aboutSection;