import React, { Component } from "react";
import { Link } from "react-router-dom";

// Elements
import FeatureSection3 from "../elements/feature-section3";
import TeamSection from "../elements/team";
import LatestNewsSection from "../elements/latest-news-slider";
import telemedicine from "../../images/icon/telemedicine.png";
// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import testPic2 from "../../images/testimonials/pic2.jpg";
import depression from "../../images/testimonials/depression.png";
import depression_image from "../../images/testimonials/despression1.png";
import anxiety from "../../images/testimonials/anxiety.png";
import ptsd from "../../images/testimonials/ptsd.jpg";

class OCD extends Component {
  render() {
    return (
          <section className="section-area section-sp3 team-wraper">
            <div className="container">
              <div className="heading-bx text-center">
                <h2 className="title-ext text-secondary">OCD</h2>
                {/* <h2 className="title">Meet Best Doctors</h2> */}
              </div>
              <div className="row justify-content-center">
                <>
                  <div style={{ display: "flex" }}>
                    <div className="col-lg-12 col-sm-6 mb-30">
                      <div className="team-member" style={{ display: "flex" }}>
                        <div className="col-lg-4">
                          <div className="team-media">
                            <img src={depression} alt={OCD} />
                          </div>
                        </div>
                        <div>
                          <div className="team-info">
                            <div className="team-info-content">
                              <h4 className="title">OCD</h4>
                            </div>
                            {/* <ul className="social-media mt-3">
												{teamMember.socialMedia.map((social, index) => (
													<li key={index}>
														<a rel="noreferrer" target="_blank" href={social.link}>
															<i className={`fab fa-${social.platform.toLowerCase()}`} />
														</a>
													</li>
												))}
											</ul> */}
                          </div>
                          <p
                            style={{
                              textAlign: "left",
                              marginLeft: "1rem",
                              color: "#1f2278",
                            }}
                          >
                            Obsessive-Compulsive Disorder (OCD) is a mental
                            health condition characterized by persistent and
                            intrusive thoughts (obsessions) that lead to
                            repetitive behaviors or mental acts (compulsions)
                            aimed at reducing the anxiety or discomfort caused
                            by the obsessions.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>

            {/* <img className="pt-img4 animate-wave" src={ptImg4} alt=""/>
					<img className="pt-img5 animate-wave" src={ptImg5} alt=""/> */}
          </section>
    );
  }
}

export default OCD;
