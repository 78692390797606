import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Elements
import LatestNewsSection from "../elements/latest-news-slider";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";

class Policies extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Policies</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Policies</li>
										</ul>
									</nav>
								</div>
							</div>
							{/* <img className="pt-img1 animate-wave" src={waveBlue} alt=""/> */}
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-sp3">
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									<Accordion defaultActiveKey="0" className="accordion ttr-accordion1">
										<Accordion.Item eventKey="0">
											<Accordion.Header>New Patient Registration</Accordion.Header>
											<Accordion.Body>
												<p className="mb-1"><b style={{fontWeight:700}}>1.</b> Book an Appointment <a href="/booking">Online</a></p>
												<p className="mb-1"><b style={{fontWeight:700}}>2.</b> After booking appointment a link to fill and submit intake form will be emailed on the provided email id.  Please fill and submit the intake form before 24hrs of appointment. All information is kept confidential and is used for provider awareness.  A member of our staff will review your information and may contact you by reply email for additional information. Note: We continue to experience a higher than usual demand. Our staff makes every effort to respond to new patient requests in a timely manner.  Please allow 2 business days for a response.  </p>
												<p className="mb-1"><b style={{fontWeight:700}}>3.</b> Once you are a registered patient and your appointment is accepted, an e-mail with video link with be sent to the registered email id with next 24 hrs.</p>
												<p className="mb-0"><b style={{fontWeight:700}}>4.</b> Practical Psychiatric Solutions reserves the right to cancel and/or reschedule intake appointments if the registration is not completed within 48 hours. </p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="1">
											<Accordion.Header>Cancellation Policies</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">
												We do have a strict No-Show policy and you will be charged half the appointment fee if you are a No-Show. If you are more than 10 minutes late for any appointment, you will be considered a No-Show and charged accordinglyAlthough we understand that it may be necessary to miss an appointment, it is crucial for you to cancel your appointment online or via phone call at least 12 hours before your schedule. Otherwise, you will be charged 100% of the service fee amid cancellation.
												</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="2">
											<Accordion.Header>Follow up Visit</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">
													After you book an appointment you will get an email confirmation once your appointment is accepted. Within 24 hrs you will be receive a video link for the appointment on registered email ID
												</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="2">
											<Accordion.Header>Reminders</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">
													You will receive reminder for the scheduled call 30 mins prior to the appointment. Please join the call 5 mins prior to the appointment.
												</p>
											</Accordion.Body>
										</Accordion.Item>
										{/* <Accordion.Item eventKey="3">
											<Accordion.Header>What types of systems do you support?</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="4">
											<Accordion.Header>We Teach You How To Feel Better?</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="5">
											<Accordion.Header>How Can I Contact You?</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
											</Accordion.Body>
										</Accordion.Item> */}
									</Accordion>
								</div>
								{/* <div className="col-lg-6">
									<Accordion defaultActiveKey="2" className="accordion ttr-accordion1">
										<Accordion.Item eventKey="0">
											<Accordion.Header>How do I withdraw from a subject?</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="1">
											<Accordion.Header>What’s a payment statement?</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="2">
											<Accordion.Header>How Can I Contact You?</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="3">
											<Accordion.Header>How do I withdraw from a subject?</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="4">
											<Accordion.Header>Understand Doctor Before You Regret?</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="5">
											<Accordion.Header>How Doctor Can Ease Your Pain?</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</div> */}
							</div>
						</div>
					</section>
					
					{/* <LatestNewsSection /> */}
					
				</div>
			
			</>
		);
	}
}

export default Policies;